<template>
  <div class="notification-view">
    <div v-if="$route.params.id" class="detail-list">
      <div class="go-back" @click="$router.back()">
        <img alt="" src="~@/assets/images/left-arrow.png" />
        <p>消息中心</p>
      </div>
      <div v-for="(item, idx) in messageDetails" :key="idx" class="detail-item">
        <p v-text="parseDetailDatetime(item.create_date)"></p>
        <div class="detail-content">
          <p v-text="item.title"></p>
          <div class="divider"></div>
          <p v-text="item.context"></p>
        </div>
      </div>
    </div>
    <template v-else>
      <div v-if="!!messages.length" class="msg-list">
        <router-link
          v-for="(item, idx) in messages"
          :key="idx"
          :to="`/member_center/notification/${item.msg_id}`"
          class="msg-item flex-ac flex-jb"
        >
          <div class="msg-icon flex-c">
            <a-badge :count="item.num_record">
              <img alt="" src="~@/assets/images/bell.png" />
            </a-badge>
          </div>
          <div class="msg">
            <p class="title" v-text="item.msg_title"></p>
            <p class="subtitle" v-text="item.subTitle"></p>
          </div>
          <div class="create-date" v-text="item.subCreateDate"></div>
        </router-link>
      </div>
      <div v-else-if="!loadingMessages" class="empty">暫無數據</div>
      <div
        v-if="!loadingMessages && !!messages.length"
        class="mark-all-read btn active"
        @click="markAllRead"
      >
        全部标示已读
      </div>
    </template>
  </div>
</template>

<script>
import { post } from '@/axios'
import _ from 'lodash'
import dayjs from 'dayjs'

export default {
  name: 'notification',
  data() {
    return {
      loadingMessages: true,
      messages: [],
      messageDetails: []
    }
  },
  async created() {
    await this.getMessageList()
  },
  methods: {
    async getMessageList() {
      const mapStr = JSON.stringify({})

      try {
        const { code, info, data } = await post(
          'member_mobile_getMessageCenter.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { msgList } = JSON.parse(data)
          this.messages = msgList
        }
      } catch {
      } finally {
        this.loadingMessages = false
      }
    },
    async getMessageByMsgId(msg_id) {
      const mapStr = JSON.stringify({
        msg_id
      })

      await this.setMessageRead(msg_id)

      try {
        const { code, info, data } = await post(
          'member_mobile_getMessageCenterList.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { messageList, msg_id: rtnMsgId } = JSON.parse(data)
          if (msg_id === rtnMsgId) {
            this.messageDetails = messageList
          }
        }
      } catch {
      }
    },
    async setMessageRead(msg_id) {
      const mapStr = JSON.stringify(msg_id ? { msg_id } : {})

      try {
        const { code } = await post(
          'member_mobile_updateMessageRead.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        }
      } catch {
      }
    },
    async markAllRead() {
      try {
        await this.setMessageRead()
      } catch {
      } finally {
        await this.getMessageList()
      }
    },
    parseDetailDatetime(createDate) {
      return dayjs(createDate).format('YYYY-MM-DD HH:mm')
    }
  },
  computed: {
    messageDetail() {
      return _.first(this.messageDetails)
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        const msg_id = route.params.id
        if (msg_id) {
          if (this.messages.length === 0) {
            await this.getMessageList()
          }

          const target = this.messages.find(msg => msg.msg_id === msg_id)

          const msgTitle = target ? target.msg_title : ''
          this.$store.commit('setMsgTitle', msgTitle)

          await this.getMessageByMsgId(msg_id)
        } else {
          this.$store.commit('setMsgTitle', '')
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('setMsgTitle', '')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';

.notification-view {
  padding: 1rem 5%;

  .msg-list {
    width: 100%;

    .msg-item {
      width: 100%;
      background-color: $item-bg;
      color: #999;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &:hover {
        background-color: $item-bg-hover;
        // color: #8d8d8d;
        cursor: pointer;
      }

      > .msg {
        flex: 1;
        text-align: left;
        text-indent: 1rem;
        line-height: 1.5;

        .title {
          color: #e0e0e0;
        }
      }

      .create-date {
        color: #999;
        margin-right: 1rem;
      }

      .msg-icon {
        background: center/contain no-repeat url('~@/assets/images/item-tag.png');

        width: 3.5rem;
        height: 3.5rem;

        img {
          width: 1.5rem;
        }
      }
    }
  }

  .detail-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-weight: 100;
    letter-spacing: 1px;
    font-size: 0.9rem;
    margin-bottom: 3rem;

    > .go-back {
      display: flex;
      align-items: center;
      color: #e0e0e0;
      cursor: pointer;
      user-select: none;

      img {
        width: 1.2rem;
        margin-right: 1rem;
      }
    }

    > .detail-item {
      color: #e0e0e0;
      margin-top: 1rem;
      font-size: 0.8rem;

      .detail-content {
        text-align: left;
        background-color: $item-bg;
        padding: 1rem 0.8rem;
        margin-top: 0.5rem;

        .divider {
          width: 100%;
          margin: 0.5rem auto;
          height: 1px;
          background-color: #999;
        }
      }

      &:hover {
        .detail-content {
          background-color: $item-bg-hover;
        }
      }
    }
  }

  .empty {
    width: 100%;
    color: #666;
    padding: 2rem;
    text-align: center;
  }

  .mark-all-read {
    position: absolute;
    right: 2rem;
    top: 0.5rem;
    color: white;
    padding: 0.4rem;
    font-size: 0.8rem;
    border-radius: 5px;
  }

  @include md {
    padding: 0;

    .msg-list {
      margin: 3rem 0;

      .msg-item {
        padding: 0;
        font-size: 0.7rem;

        .msg-icon {
          width: 3rem;
          height: 3rem;

          img {
            width: 1.2rem;
          }
        }

        .create-date {
          position: absolute;
          right: 0.5rem;
          margin-right: 0;
          bottom: 0.5rem;
        }
      }
    }

    .mark-all-read {
      right: 0.5rem;
      top: 0.5rem;
      font-size: 0.7rem;
    }

    .go-back {
      display: none !important;
    }
  }
}
</style>
